import { Col, Row } from "antd";
import React from "react";
import DeliveryOrderDetails from "./DeliveryOrderDetails";
import DeliveryTable from "./DeliveryTable";

export default function Delivery() {
  return (
    <div id="delivery">
      <Row>
        <Col span={16}>
          <div>
            <DeliveryTable />
          </div>
        </Col>
        <Col span={8}>
          <DeliveryOrderDetails />
        </Col>
      </Row>
    </div>
  );
}
