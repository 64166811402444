import { Layout } from "antd";
import React from "react";
import { renderRoutes } from "react-router-config";
import CommonFooter from "../../components/CommonFooter";
import Navigation from "../../components/Navigation";
import "./style.scss";

const { Content, Footer } = Layout;

export default function MainLayout(props) {
  const { route } = props;
  return (
    <>
      <div>
        <Layout>
          <Navigation />
          <Content>
            <div>{renderRoutes(route.routes)}</div>
          </Content>
          <Footer>
            <CommonFooter />
          </Footer>
        </Layout>
      </div>
    </>
  );
}
