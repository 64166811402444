import React from "react";
import jwt_decode from "jwt-decode";
import { Link, useHistory } from "react-router-dom";
import { Menu, Layout, Button, Drawer, Dropdown, Row } from "antd";
import { BarsOutlined, LogoutOutlined } from "@ant-design/icons";

import "./style.scss";
import BrandLogo from "../../assets/images/one-liner-logo.png";
import UserIcon from "../../assets/images/user-icon.png";
import DownArrow from "../../assets/images/down-arrow.png";

import { useAuth } from "../../services/auth";
import useWindowSize from "../../hooks/useWindowSize";
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";
import PrimaryButton from "../CustomButtons/PrimaryButton";
import { HashLink } from "react-router-hash-link";

export default function Navigation() {
  const history = useHistory();
  const { width: windowWidth } = useWindowSize();
  const { signInWithGoogle, signOut, userProfile } = useAuth();
  const [isMobileMenu, setIsMobileMenu] = React.useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  function toggleMobileMenuOpen() {
    if (isMobileMenu) setMobileMenuOpen(!mobileMenuOpen);
  }

  function ProfileMenu() {
    return (
      <Menu style={{ border: "1px solid #f0f0f0" }}>
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        >
          Log out
        </Menu.Item>
      </Menu>
    );
  }

  function handleLogout() {
    signOut();
    localStorage.removeItem("flunkeyAuthToken");
    localStorage.removeItem("flunkeyMerchantStore");
    history.push("/admin/overview");
  }

  function DesktopNavbar() {
    return (
      <Menu mode="horizontal" theme="light">
        <Row justify="space-between">
          <div className="links">
            <Menu.Item key={0} className="pt-4">
              <Link to="/">
                <Logo height={30} />
              </Link>
            </Menu.Item>
          </div>
          <Row className="links">
            <Menu.Item key={1}>
              <HashLink smooth to="/#product">Product</HashLink>
            </Menu.Item>
            <Menu.Item key={2}>
              <HashLink smooth to="/#pricing">Pricing</HashLink>
            </Menu.Item>
            <Menu.Item key={3}>
              <Link to="/about-us">About Us</Link>
            </Menu.Item>
            <Menu.Item key={4}>
              <Link to="/FAQ">FAQs</Link>
            </Menu.Item>
            {/* <Menu.Item key={5}>
              <HashLink smooth to="#contact">Contact</HashLink>
            </Menu.Item> */}
            <Menu.Item key={6}>
              {/* <Link to="/blog">Blog</Link> */}
              <a href="https://events.flunkey.app/" target={"_blank"}>What's Up Today</a>
            </Menu.Item>
            <Menu.Item key={7}>
              <PrimaryButton
                className="gradient-reverse font-semibold"
                text="Login"
                onClick={signInWithGoogle}
              />
            </Menu.Item>
          </Row>
        </Row>
      </Menu>
    );
  }

  function MobileNavbar() {
    return (
      <Menu theme="light" mode="horizontal">
        <Menu.Item key={0}>
          <Link to="/">
            <Logo height={30} className="mb-4" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key={1}
          style={{ padding: 0, border: "none", float: "right" }}
        >
          <Button
            onClick={() => toggleMobileMenuOpen()}
            icon={<BarsOutlined style={{ marginRight: 0 }} />}
            type="ghost"
            style={{ marginLeft: 10 }}
            size="large"
          />
        </Menu.Item>
      </Menu>
    );
  }

  function DrawerMenu() {
    return (
      <div className="flex flex-col justify-between h-full">
        <div>
          <Menu className="links">
            <Menu.Item onClick={toggleMobileMenuOpen} key={1}>
              <HashLink to="#product">Product</HashLink>
            </Menu.Item>
            <Menu.Item onClick={toggleMobileMenuOpen} key={2}>
              <HashLink to="#pricing">Pricing</HashLink>
            </Menu.Item>
            <Menu.Item onClick={toggleMobileMenuOpen} key={3}>
              <Link to="/about-us">About Us</Link>
            </Menu.Item>
            <Menu.Item onClick={toggleMobileMenuOpen} key={4}>
              <Link to="/FAQ">FAQs</Link>
            </Menu.Item>
            {/* <Menu.Item onClick={toggleMobileMenuOpen} key={5}>
              <Link to="/contact">Contact</Link>
            </Menu.Item> */}
            <Menu.Item onClick={toggleMobileMenuOpen} key={6}>
              {/* <Link to="/blog">Blog</Link> */}
              <a href="https://events.flunkey.app/" target={"_blank"}>What's Up Today</a>
            </Menu.Item>
          </Menu>
        </div>
        <PrimaryButton
          className="gradient-reverse font-semibold m-10"
          text="Login"
          onClick={signInWithGoogle}
        />
      </div>
    );
  }

  React.useEffect(() => {
    console.log(windowWidth);
    if (windowWidth >= 1100) {
      setIsMobileMenu(false);
    } else {
      setIsMobileMenu(true);
    }
  }, [windowWidth]);

  React.useEffect(() => {
    if (userProfile) {
      const decodedJWT = jwt_decode(userProfile?.Aa);
      localStorage.setItem("flunkeyAuthToken", `Bearer ${userProfile?.Aa}`);
      if (decodedJWT?.store) {
        history.push("/admin");
        localStorage.setItem("flunkeyMerchantStore", decodedJWT?.store);
      } else {
        history.push("/register");
      }
    }
  }, [history, userProfile]);

  return (
    <>
      {isMobileMenu && (
        <Drawer
          title={<Logo className="m-10" />}
          headerStyle={{ padding: 0 }}
          bodyStyle={{ padding: 0 }}
          placement="left"
          closable={false}
          onClose={toggleMobileMenuOpen}
          visible={mobileMenuOpen}
        >
          <DrawerMenu />
        </Drawer>
      )}
      <Layout.Header className="bg-white fixed w-full z-50 shadow-md">
        {isMobileMenu ? <MobileNavbar /> : <DesktopNavbar />}
      </Layout.Header>
    </>
  );
}
