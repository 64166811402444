import React from 'react'
import '../../assets/styles/global.scss'
import clsx from 'clsx'

function PrimaryButton(props) {
  const {
    text,
    type,
    disabled,
    onClick,
    className,
    horizontalPadding = 4,
    reverseGradient,
  } = props

  return (
    <button
      type={type}
      className={clsx(
        {
          ['cursor-not-allowed opacity-40']: disabled,
          ['gradient']: !reverseGradient,
          ['gradient-reverse']: reverseGradient,
        },
        `px-${horizontalPadding} border py-1 rounded-md text-sm tracking-wide capitalize ${className}`,
      )}
      onClick={!disabled ? onClick : null}
      style={{ borderColor: 'orange' }}
    >
      {text}
    </button>
  )
}

export default PrimaryButton
