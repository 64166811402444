import { EditOutlined } from "@ant-design/icons";
import { Popconfirm, Row } from "antd";
import clsx from "clsx";
import React from "react";
import CommonModal from "../../../../components/Modal";
import CategoryForm from "./CategoryForm";
import DeleteCategoryModal from "./DeleteCategoryModal";

function CategoryCard(props) {
  const {
    category,
    refetchCategory,
    setActiveCategory,
    activeCategory,
    index,
    provided,
  } = props;
  const [isDeleteCategoryModalVisible, setDeleteCategoryModalVisible] =
    React.useState(false);
  const [isEditCategoryFormVisible, setEditCategoryFormVisible] =
    React.useState(false);

  const handleCancel = () => {
    setDeleteCategoryModalVisible(false);
    setEditCategoryFormVisible(false);
  };
  return (
    <>
      <Row
        justify="space-between"
        className={clsx("m-3 px-4 py-3 rounded-md shadow-md text-base", {
          "font-semibold active-category":
            category?.ckey === activeCategory?.ckey,
        })}
        onClick={() => setActiveCategory(category)}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <span>
          {index + 1}. {category.name}
        </span>
        <span>
          <Popconfirm
            title="Are you sure to perform this actions?"
            onConfirm={() => {
              setDeleteCategoryModalVisible(true);
            }}
            onCancel={() => setEditCategoryFormVisible(category)}
            okText="Delete"
            cancelText="Edit"
          >
            <EditOutlined />
          </Popconfirm>
        </span>
      </Row>

      {/* ADD New Category Modal */}
      <CommonModal
        title="Edit Category"
        isModalOpen={isEditCategoryFormVisible}
        handleCancel={handleCancel}
      >
        <CategoryForm
          category={category}
          handleCancel={handleCancel}
          refetchCategory={refetchCategory}
        />
      </CommonModal>

      {/* Delete Category Modal */}
      <CommonModal
        isModalOpen={isDeleteCategoryModalVisible}
        handleCancel={handleCancel}
        modalWidth={350}
      >
        <DeleteCategoryModal
          category={category}
          handleCancel={handleCancel}
          refetchCategory={refetchCategory}
        />
      </CommonModal>
    </>
  );
}

export default CategoryCard;
