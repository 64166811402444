import { TextField } from "@mui/material";
import { Checkbox, Col, Form, Input, Row } from "antd";
import React from "react";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";

const BusinessDetails = () => {
  return (
    <>
      {" "}
      <h1 className="text-2xl pb-2 tracking-wide font-semibold">
        Business Detail
      </h1>
      <div className="text-xs pb-2">
        We will need some information about the Food outlet. This <br />
        will help you manage your business better.
      </div>
      <Form.Item
        name="restaurantName"
        required={false}
        rules={[{ required: true, message: "Required" }]}
      >
        <TextField
          label="Food Outlet's Name"
          className="w-full input"
          variant="standard"
          color="warning"
        />
      </Form.Item>
      <Form.Item
        label="A short description"
        name="description"
        required={false}
      >
        <Input.TextArea
          className="w-full rounded-md"
          placeholder="something you known for ..."
        />
      </Form.Item>
      <Form.Item label="Outlet Type">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="allowReservations" valuePropName="checked">
              <Checkbox checked={false}>Dine In</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="allowTakeaway" valuePropName="checked">
              <Checkbox checked={false}>Takeaway</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="allowDelivery" valuePropName="checked">
              <Checkbox checked={false} style={{ whiteSpace: "nowrap" }}>
                Self Delivery
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item name="avgTime">
        <TextField
          variant="standard"
          className="w-full input"
          color="warning"
          type="number"
          label="Average Time Taken to fulfill an order (in mins)"
        />
      </Form.Item>
      <Form.Item>
        <div align="center" className="pt-6">
          <PrimaryButton
            className="w-full"
            reverseGradient
            type="submit"
            text={"CONTINUE"}
          />
        </div>
      </Form.Item>
    </>
  );
};

export default BusinessDetails;
