import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="">
      <iframe
        src="https://docs.google.com/document/d/e/2PACX-1vQtt_sE_v6bR4rzYi_eADcZOW9ndi-AvSTm5hW4BmclGN9AfsiD_dJOnk8KYHysEwiv2y4CESSidNiw/pub?embedded=true"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        className="m-auto"
        style={{
          width: "900px",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        Loading…
      </iframe>
    </div>
  );
};
export default PrivacyPolicy;
