import { Modal } from "antd";
import React from "react";

export default function CommonModal(props) {
  const {
    title,
    isModalOpen,
    children,
    handleCancel,
    modalWidth = 500,
    destroyOnClose,
  } = props;

  return (
    <div>
      <Modal
        title={title}
        visible={isModalOpen}
        footer={false}
        onCancel={handleCancel}
        width={modalWidth}
        destroyOnClose={destroyOnClose}
      >
        {children}
      </Modal>
    </div>
  );
}
