import Axios from "axios";
import { axiosInitialParams } from "../../utils";
const baseAPIUrl = process.env.REACT_APP_API_SERVER;
const axios = Axios.create({
    baseURL: baseAPIUrl,
    axiosInitialParams,
});
// return all agents
export const getAllAgents = async (token) => {
    token = await token;
    return axios.get("/delivery/agent", {
        headers: { Authorization: "Bearer " + token },
    });
};

/// delete agent by id
export const deleteAgentById = async (token, id) => {
    token = await token;
    return axios.delete("/delivery/agent/" + id, {
        headers: { Authorization: "Bearer " + token },
    });
};

/// Update agent by id
export const updateAgentById = async (token, body, id) => {
    token = await token;
    return axios.put("/delivery/agent/" + id, body, {
        headers: { Authorization: "Bearer " + token },
    });
};

/// Add new agent 
export const addAgent = async (token, body) => {
    token = await token;
    return axios.post("/delivery/agent", body, {
        headers: { Authorization: "Bearer " + token },
    });
};