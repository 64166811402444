import { DeleteFilled } from '@ant-design/icons'
import { Col, Popconfirm, Row, Table } from 'antd'
import moment from 'moment'
import React from 'react'
import { useAuth } from '../../../services/auth'
import clsx from 'clsx'

// Invoice Print Details
const PrintDetails = (props) => {
  const { getIdToken } = useAuth()
  const token = getIdToken()

  return (
    <div className="p-5 text-center flex flex-col" style={{ height: '90vh' }}>
      <div className="flex-none">
        <div className="p-2">
          <h2 className="text-xl font-bold">
            {props?.orderInvoiceData?.data?.data?.restaurantName}
          </h2>
          <div className="text-md">
            {props?.orderInvoiceData?.data?.data?.restaurantAddress}
          </div>
        </div>

        <hr style={{ borderTop: '1px dashed' }} />

        <Row justify="space-between" className="p-2">
          <Col>Bill No : {props.tabId}</Col>
          <Col>
            {moment(
              Date.parse(props?.orderInvoiceData?.data?.data?.createdAt),
            ).format('DD MMMM YYYY hh:mm')}
          </Col>
        </Row>

        <hr style={{ borderTop: '1px dashed' }} />
      </div>

      <div className="p-2 flex-grow">
        <Table
          size="small"
          pagination={{
            position: ['none'],
            pageSize: props?.orderInvoiceData?.data?.data?.items.length,
          }}
          columns={[
            {
              title: 'S.No',
              dataIndex: 'index',
              key: 'index',
              width: 40,
              render: (index) => index + 1,
            },
            {
              title: 'Name',
              dataIndex: 'itemName',
              key: 'name',
              width: 80,
            },
            {
              title: 'Qty',
              dataIndex: 'quantity',
              key: 'qty',
              width: 40,
            },
            {
              title: 'Price',
              dataIndex: 'price',
              key: 'price',
              render: (price) => {
                return Number(price).toFixed(2)
              },
              width: 50,
            },
            {
              title: 'Total',
              key: 'total',
              dataIndex: 'total',
              render: (total) => {
                return Number(total).toFixed(2)
              },
              width: 50,
            },
          ]}
          dataSource={props?.orderInvoiceData?.data?.data?.items}
          className="table-white"
        />
        <hr style={{ borderTop: '1px dashed' }} />
        <Row justify="space-between">
          <Col className="text-left text-gray-600 font-medium">
            <div className="py-2">Cart Value</div>
            <div className="py-2">Service Charge</div>
            <div className="py-2">GST</div>
            <div className="py-2">Tip</div>
          </Col>
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">
              $ {props?.orderInvoiceData?.data?.data?.totalCart?.toFixed(2)}
            </div>
            <div className="py-2">
              $ {props?.orderInvoiceData?.data?.data?.serviceCharge?.toFixed(2)}
            </div>
            <div className="py-2">
              $ {props?.orderInvoiceData?.data?.data?.gst?.toFixed(2)}
            </div>
            <div className="py-2">
              $ {props?.orderInvoiceData?.data?.data?.tip?.toFixed(2)}
            </div>
          </Col>
        </Row>
        <hr style={{ borderTop: '1px dashed' }} />
        <Row justify="space-between">
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">Total</div>
          </Col>
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">
              $ {props?.orderInvoiceData?.data?.data?.total?.toFixed(2)}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

// KOT Print Details
export const PrintKOTDetails = (props) => {
  const { getIdToken } = useAuth()
  const token = getIdToken()
  return (
    <div className="p-5 text-center flex flex-col" style={{ height: '90vh' }}>
      <div className="flex-none">
        <div className="p-2">
          <h2 className="text-xl font-bold">{props?.tabId}</h2>
        </div>

        <hr style={{ borderTop: '1px dashed' }} />

        <Row justify="space-between" className="p-2">
          <Col>Order Id : {props.orderInvoiceData?.orderId}</Col>
          <Col>Tab Id : {props.orderInvoiceData?.tabId}</Col>
          <Col>
            {moment(Date.parse(props.orderInvoiceData?.createdAt)).format(
              'DD MMMM YYYY hh:mm',
            )}
          </Col>
        </Row>
        <hr style={{ borderTop: '1px dashed' }} />
      </div>

      <div className="p-2 flex-grow">
        <Table
          size="small"
          pagination={{
            position: ['none'],
            pageSize: props?.orderInvoiceData?.items?.length,
          }}
          columns={[
            {
              title: 'S.No',
              dataIndex: 'index',
              key: 'index',
              width: 40,
              render: (index) => index + 1,
            },
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              width: 200,
            },
            {
              title: 'Qty',
              dataIndex: 'nos',
              key: 'nos',
              width: 40,
              align: 'right',
            },
          ]}
          dataSource={props.orderInvoiceData?.items}
          className="table-white"
        />
        <hr style={{ borderTop: '1px dashed' }} />

        <Row justify="space-between">
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2 ">Note</div>
          </Col>
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">{props.orderInvoiceData?.note}</div>
          </Col>
        </Row>

        <hr style={{ borderTop: '1px dashed' }} />
        <Row justify="space-between">
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">TotalQty</div>
          </Col>
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">{props.totalQty}</div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PrintDetails
