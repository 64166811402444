import { DeleteFilled } from '@ant-design/icons'
import { Col, Popconfirm, Row, Table } from 'antd'
import moment from 'moment'
import React from 'react'
import { useAuth } from '../../../services/auth'
import clsx from 'clsx'
import PrimaryButton from '../../../components/CustomButtons/PrimaryButton'
import ReactToPrint from 'react-to-print'
import OutlinedButton from '../../../components/CustomButtons/OutlinedButton'
import PrintDetails from './PrintDetails'
import { useState } from 'react'

const InvoiceDetails = (props) => {
  let serialNumber = 1;

  const { getIdToken } = useAuth()
  const token = getIdToken()
  let ref = React.useRef()

  const columns = [
    {
      title: 'Sn',
      dataIndex: 'index',
      key: 'index',
      width: 35,
      render: ()  => 
        <span>{serialNumber++}</span>,
    },
    {
      title: 'Name',
      dataIndex: 'itemName',
      key: 'name',
      width: 80,
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'qty',
      width: 40,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => {
        return Number(price).toFixed(2)
      },
      width: 60,
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      render: (total) => {
        return Number(total).toFixed(2)
      },
      width: 60,
    },
  ]

  if (props.currentTab?.tabOpen) {
    columns.push({
      title: '',
      key: 'delete',
      render: (item) => {
        return (
          <Popconfirm
            title="Are you sure to perform this actions?"
            onConfirm={() =>
              props.removeItemFromInvoiceMutation({
                token: token,
                params: {
                  itemId: item.itemId,
                  orderId: item.orderId,
                },
              })
            }
            okText="Delete"
          >
            <DeleteFilled />
          </Popconfirm>
        )
      },
      width: 30,
    })
  }

  return (
    <div className="p-5 text-center flex flex-col" style={{ height: '90vh' }}>
      <div className="flex-none">
        <div className="p-1">
          <h2 className="text-xl font-bold">
            {props.orderInvoiceData?.data?.data?.restaurantName}
          </h2>
          <div className="text-md">
            {props.orderInvoiceData?.data?.data?.restaurantAddress}
          </div>
        </div>

        <hr style={{ borderTop: '1px dashed' }} />

        <Row justify="space-between" className="p-2">
          <Col>Bill No : {props.currentTab?.tabId}</Col>
          <Col>
            {moment(
              Date.parse(props.orderInvoiceData?.data?.data?.createdAt),
            ).format('DD MMMM YYYY hh:mm')}
          </Col>
        </Row>

        <hr style={{ borderTop: '1px dashed' }} />
      </div>

      <div className="p-1 flex-grow">
        <Table
          size="small"
          scroll={{ y: `calc(100vh - 500px)` }}
          pagination={{
            position: ['none'],
            pageSize: props?.orderInvoiceData?.data?.data?.items.length,
          }}
          columns={columns}
          dataSource={props?.orderInvoiceData?.data?.data?.items}
          className="table-grey"
        />
        <hr style={{ borderTop: '1px dashed' }} />
        <Row justify="space-between">
          <Col className="text-left text-gray-600 font-medium">
            <div className="py-1 pt-2">Cart Value</div>
            <div className="py-1">Service Charge</div>
            <div className="py-1">GST</div>
            <div className="py-1">Tip</div>
          </Col>
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-1 pt-2">
              $ {props.orderInvoiceData?.data?.data?.totalCart?.toFixed(2)}
            </div>
            <div className="py-1">
              $ {props.orderInvoiceData?.data?.data?.serviceCharge?.toFixed(2)}
            </div>
            <div className="py-1">
              $ {props.orderInvoiceData?.data?.data?.gst?.toFixed(2)}
            </div>
            <div className="py-1">
              $ {props.orderInvoiceData?.data?.data?.tip?.toFixed(2)}
            </div>
          </Col>
        </Row>
        <hr style={{ borderTop: '1px dashed' }} />
        <Row justify="space-between">
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">Total</div>
          </Col>
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">
              $ {props.orderInvoiceData?.data?.data?.total?.toFixed(2)}
            </div>
          </Col>
        </Row>
      </div>
      <div className="flex-none">
        <Row justify="center" gutter={[24, 12]} className="px-3 my-2">
          <Col>
            <OutlinedButton
              handleClick={() => props.setInvoiceView(false)}
              text="Back"
            />
          </Col>

          <Col>
            <ReactToPrint
              trigger={() => <PrimaryButton text="Print" />}
              content={() => ref}
            />
          </Col>
        </Row>
      </div>

      <div className="hidden">
        <div ref={(el) => (ref = el)}>
          <PrintDetails
            orderInvoiceData={props.orderInvoiceData}
            tabId={props.currentTab?.tabId}
          />
        </div>
      </div>
    </div>
  )
}
export default InvoiceDetails
