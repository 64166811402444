import { atom, selector } from "recoil";

/*
 * @Author: Chintan Sudani
 * @Description: This file includes state
 * management recoil of Auth services.
 * @Reference:http://recoiljs.org/docs/introduction/getting-started/
 */

export const activeReservationsTabAtom = atom({
  key: "activeReservationsTabAtom",
  default: "All",
});

export const allReservationListAtom = atom({
  key: "allReservationListAtom",
  default: undefined,
});

export const filteredReservationDataSelector = selector({
  key: "filteredReservationDataSelector",
  get: ({ get }) => {
    const activeReservationStatus = get(activeReservationsTabAtom);
    const reservationLists = get(allReservationListAtom);
    if (activeReservationStatus === "All") {
      return reservationLists;
    } else {
      return reservationLists
        ?.filter(
          (item) => item.status === activeReservationStatus.toUpperCase()
        )
        .map((i) => i);
    }
  },
});
