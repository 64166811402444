import { TextField } from "@mui/material";
import { Modal, Col, Form, Row, Checkbox } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import GoogleMapLocationSelector from "../GoogleMapLocationSelector";
import React, { useState } from "react";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";

const LocationDetails = (props) => {
  const [mapModalVisible, setMapModalVisible] = React.useState(false);
  const { location, setLocation, form, isLoadingRegistration } = props;
  const [checked, setChecked] = useState(false);
  const [termsConditionModal, setTermsConditionModal] = useState(false);
  return (
    <>
      {" "}
      <h1 className="text-2xl pb-2 tracking-wide font-semibold">
        Location Detail
      </h1>
      <div className="text-xs pb-2">Help your customer to reach you.</div>
      <Form.Item
        name="address"
        required={false}
        rules={[{ required: true, message: "Required" }]}
      >
        <TextField
          label="Address"
          className="w-full input"
          variant="standard"
          color="warning"
          InputLabelProps={{ shrink: true }}
        />
      </Form.Item>
      <button
        style={{ color: "#ff5317" }}
        className="text-xs pb-4"
        onClick={(e) => {
          e.preventDefault();
          console.log("hello");
          setMapModalVisible(!mapModalVisible);
        }}
      >
        <EnvironmentOutlined style={{ fontSize: 18 }} />
        &nbsp;Locate on Map
      </button>
      <Form.Item name="neighbourhood" required={false}>
        <TextField
          variant="standard"
          className="w-full input"
          label="Neighbourhood"
          color="warning"
          InputLabelProps={{ shrink: true }}
        />
      </Form.Item>
      <Row gutter={8}>
        <Col>
          <Form.Item name="city">
            <TextField
              variant="standard"
              className="w-full input"
              color="warning"
              label="City"
              InputLabelProps={{ shrink: true }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="pincode">
            <TextField
              variant="standard"
              className="w-full input"
              color="warning"
              type="number"
              label="Pincode"
              InputLabelProps={{ shrink: true }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Checkbox
        onChange={(e) => {
          setChecked(e.target.checked);
        }}
        checked={checked}
      />
      <span className="pl-2 font-medium">I agree to </span>
      <span
        className="gradient-reverse bg-clip-text cursor-pointer"
        onClick={() => {
          setTermsConditionModal(true);
        }}
      >
        Terms and Conditions
      </span>
      <Form.Item>
        <div align="center" className="pt-6">
          <PrimaryButton
            className="w-full"
            reverseGradient
            disabled={isLoadingRegistration || !checked}
            type="submit"
            text={"FINISH REGISTERATION"}
          />
        </div>
      </Form.Item>
      <Modal
        title="Terms and Conditions"
        visible={termsConditionModal}
        width={900}
        onOk={() => setTermsConditionModal(false)}
        onCancel={() => setTermsConditionModal(false)}
        footer={false}
      >
        <iframe
          src="https://docs.google.com/document/d/e/2PACX-1vRXzltRntaPDNE2gBYA1hqDO3KEAlc41A_ZQvjfLeho4o4BjgJQBanXIA6GBRrbu8CkSA9zIiRQwjC6/pub?embedded=true"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          className="m-auto"
          style={{
            width: "900px",
            height: "50vh",
            overflow: "hidden",
          }}
        >
          Loading…
        </iframe>
      </Modal>
      <Modal
        title="Select you location"
        visible={mapModalVisible}
        width={1000}
        onOk={() => setMapModalVisible(false)}
        onCancel={() => setMapModalVisible(false)}
        footer={false}
      >
        <GoogleMapLocationSelector
          location={location}
          setMapModalVisible={setMapModalVisible}
          setLocation={setLocation}
          form={form}
        />
      </Modal>
    </>
  );
};

export default LocationDetails;
