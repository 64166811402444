import { PlusOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useMutation } from "react-query";
import PrimaryButton from "../../../../components/CustomButtons/PrimaryButton";
import OutlinedButton from "../../../../components/CustomButtons/OutlinedButton";
import { useAuth } from "../../../../services/auth";
import {
  addNewItem,
  updateInventoryItem,
} from "../../../../services/inventory";

export default function ItemForm(props) {
  const { handleCancel, activeCategory, refetchItems, item } = props;
  const { getIdToken } = useAuth();
  const token = getIdToken();
  const [form] = useForm();
  const [crop, setCrop] = React.useState({ aspect: 1 / 1, height: 110 });
  const [srcImg, setSrcImg] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [result, setResult] = React.useState(null);
  const [changeImg, setChangeImg] = React.useState(false);

  const { mutate: addNewItemMutation } = useMutation(
    (data) => addNewItem(data),
    {
      onSuccess: () => {
        console.log("Item Added Succesfully");
        refetchItems();
      },
    }
  );

  const { mutate: editItemMutation } = useMutation(
    (data) => updateInventoryItem(data),
    {
      onSuccess: () => {
        console.log("Item Updated Succesfully");
        refetchItems();
      },
    }
  );

  const onChangeImage = () => {
    setSrcImg(null);
    setImage(null);
    setResult(null);
  };

  const handleImageUpload = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    console.log(event.target.files[0]);
  };

  const getCroppedImg = async () => {
    try {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);
      setResult(base64Image);
      console.log(result);
    } catch (e) {
      console.log("crop the image");
    }
  };

  const onAddNewItemFinish = (values) => {
    // console.log(values);
    const query = {
      params: {
        name: values.name,
        description: values.description,
        price: values.price,
        units: values.units,
        available: values.available ?? false,
        ckey: activeCategory?.ckey,
        imageRaw: result,
      },
      token: token,
    };
    // console.log(query);
    addNewItemMutation(query);
    form.resetFields();
    handleCancel(false);
  };

  const onEditItemFinish = (values) => {
    const query = {
      params: {
        id: item.id,
        name: values.name,
        description: values.description,
        price: values.price,
        units: values.units,
        available: values.available ?? false,
      },
      token: token,
    };
    if (result) query.params.imageRaw = result;
    editItemMutation(query);
    form.resetFields();
    handleCancel(false);
  };

  return (
    <>
      <div align="center">
        {item?.url !== undefined && !changeImg ? (
          <div>
            <img
              src={item.url}
              alt="Item"
              style={{ width: "200px", height: "auto" }}
            />
            <OutlinedButton
              text="Change"
              handleClick={() => setChangeImg(true)}
            />
          </div>
        ) : (
          <div>
            {!result ? (
              !srcImg ? (
                <label htmlFor="photo-upload">
                  <Row className="h-48" justify="center" align="middle">
                    <div
                      align="center"
                      className="text-base rounded-full shadow-lg px-4 py-2 "
                    >
                      <PlusOutlined style={{ color: "#FF5317" }} />
                      <span align="center" className="pl-2">
                        Add Image
                      </span>
                    </div>
                    <div>
                      <input
                        id="photo-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                    </div>
                  </Row>
                </label>
              ) : (
                <div className=" mx-auto selected-image w-3/5">
                  <ReactCrop
                    src={srcImg}
                    onImageLoaded={setImage}
                    crop={crop}
                    onChange={setCrop}
                  />
                  <OutlinedButton text="Done" handleClick={getCroppedImg} />
                </div>
              )
            ) : (
              <div>
                <img
                  src={result}
                  alt="Item"
                  style={{ width: "200px", height: "auto" }}
                />
                <OutlinedButton text="Change" handleClick={onChangeImage} />
              </div>
            )}
          </div>
        )}
      </div>

      <Form
        name="new_item_form"
        form={form}
        layout="vertical"
        initialValues={item}
        className="pt-5"
        // labelCol={{
        //   span: 8,
        // }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={item === undefined ? onAddNewItemFinish : onEditItemFinish}
      >
        <Row gutter={24} align="middle">
          <Col span={16}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Row className="mt-8">
              <Form.Item name="available" valuePropName="checked">
                <Switch />
              </Form.Item>
              <span className="text-lg pl-2 font-semibold">Available</span>
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Quantity"
              name="units"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}
        >
          <Input.TextArea size="large" />
        </Form.Item>
        <PrimaryButton text="Submit" type="submit" />
      </Form>
    </>
  );
}
