import { atom, selector } from "recoil";

/*
 * @Author: Chintan Sudani
 * @Description: This file includes state
 * management recoil of Auth services.
 * @Reference:http://recoiljs.org/docs/introduction/getting-started/
 */

export const currentFoodStatusTabAtom = atom({
  key: "currentFoodStatusTabAtom",
  default: "PENDING",
});

export const currentTabIdAtom = atom({
  key: "currentTabIdAtom",
  default: undefined,
});

export const orderInvoiceDetailsByIdAtom = atom({
  key: "orderInvoiceDetailsByIdAtom",
  default: undefined,
});

export const liveOrderDataAtom = atom({
  key: "liveOrderDataAtom",
  default: undefined,
})

export const waiterRequestAtom = atom({
  key: "waiterRequestAtom",
  default: undefined,
})

export const tabDataAtom = atom({
  key: "tabDataAtom",
  default: undefined,
})

export const tabDataStatsSelector = selector({
  key: 'tabDataStatsSelector',
  get: ({ get }) => {
    const tabList = get(tabDataAtom);
    const pendingTabs = tabList?.filter((item) => item.status === 'PENDING').length;
    const openTabs = tabList?.filter((item) => item.status === 'ACCEPTED').length;
    return {
      pendingTabs,
      openTabs,
    };
  },
});