import Axios from "axios";
import { axiosInitialParams } from "../../utils";

const baseAPIUrl = process.env.REACT_APP_API_SERVER;
const axios = Axios.create({
  baseURL: baseAPIUrl,
  axiosInitialParams,
});

// return all open tabs
export const getTabs = async ({ token, paid, status }) => {
  token = await token;
  var url = "/merchant/tabs?";
  if (paid !== undefined) {
    url += `paid=${paid}`;
  }
  if (status !== undefined) {
    url += `&status=${status}`;
  }
  return axios.get(url, {
    headers: { Authorization: "Bearer " + token },
  });
};

// return all open tabs
export const getTabById = async ({ token, tabId }) => {
  token = await token;
  return axios.get(`/merchant/tabs/${tabId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

// return all open tabs
export const updateTabStatus = async ({ token, tabId, params }) => {
  token = await token;
  return axios.post(`/merchant/tabs/updateStatus/${tabId}`, params, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const removeItemFromInvoice = async ({ token, params }) => {
  token = await token;
  return axios.post(
    `/merchant/liveOrders/removeItems?itemId=${params.itemId}&orderId=${params.orderId}`,
    params,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
};

// Get tab invoice status
export const getInvoiceByTabId = async ({ token, tabId }) => {
  token = await token;
  try {
    return await axios
      .get(`merchant/tabs/invoice/${tabId}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => response)
      .catch((error) => error.response);
  } catch (error) {
    return error;
  }
};

// Update live order status
export const updateLiveOrderStatus = async ({ token, itemId, params }) => {
  token = await token;
  try {
    return await axios
      .post(`merchant/liveOrders/updateStatus/${itemId}`, params, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => response)
      .catch((error) => error.response);
  } catch (error) {
    return error;
  }
};
