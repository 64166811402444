import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useQuery } from "react-query";
import SearchHeaderComponent from "../../../components/HeaderComponents/search_header_component";
import CommonModal from "../../../components/Modal";
import {
  addTable,
  deleteTableById,
  getAllTables,
  editTable,
} from "../../../services/tables";
import { useAuth } from "../../../services/auth";
import "../../../assets/styles/global.scss";
import { TableCard } from "./tableCard";
import { TableFormModal } from "./tableForm";
import { Row } from "antd";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";
import RoundedButton from "../../../components/CustomButtons/RoundedButton";
import PrintAllQRCode from "./PrintAllQRCode";
import ReactToPrint from "react-to-print";

function Tables() {
  const { getIdToken } = useAuth();
  const token = getIdToken();

  const [isAddTableModal, setIsAddTableModal] = React.useState(false);

  const { data, refetch } = useQuery("tables", () => getAllTables(token));

  const deleteTable = async (tableId) => {
    await deleteTableById(tableId, token);
    refetch();
  };
  const updateTable = async (body, id) => {
    await editTable(id, body, token);
    refetch();
  };
  const addNewTable = async (body) => {
    await addTable(body, token);
    refetch();
  };

  const handleCancel = () => setIsAddTableModal(false);
  const showAddTableModal = () => setIsAddTableModal(true);
  let componentRef = React.useRef();

  return (
    <>
      <div id="table">
        <div className="hidden">
          <PrintAllQRCode
            tables={data?.data?.data}
            ref={(el) => (componentRef = el)}
          />
        </div>
        <SearchHeaderComponent title={"Tables"} search={false}>
          <Row justify="space-around">
            <RoundedButton onClick={showAddTableModal}>
              <PlusOutlined className="pb-1" style={{ color: "#FF5317" }} />
              <div className="inline-flex pl-2 font-bold">Add New Table</div>
            </RoundedButton>
            <ReactToPrint
              trigger={() => <PrimaryButton text="Print All QR" />}
              content={() => componentRef}
            />
          </Row>
        </SearchHeaderComponent>
        <Row style={{ height: "80vh" }} className="overflow-scroll">
          {data?.data?.data?.map((table, index) => (
            <TableCard
              key={index}
              table={table}
              deleteTable={deleteTable}
              updateTable={updateTable}
            />
          ))}
        </Row>
      </div>

      <CommonModal
        title="Add New Table"
        isModalOpen={isAddTableModal}
        handleCancel={handleCancel}
        destroyOnClose={true}
      >
        <TableFormModal onSubmit={addNewTable} handleCancel={handleCancel} />
      </CommonModal>
    </>
  );
}

export default Tables;
