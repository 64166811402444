import React from "react";

export default function NotFoundPage() {
  return (
    <div>
      <h4>
        Opps! Not found anything:( <a href="/admin">Back to home</a>
      </h4>
    </div>
  );
}
