import React from "react";
import { renderRoutes } from "react-router-config";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { routes } from "../routes";
import { AuthProvider } from "../services/auth";

import "../assets/styles/global.scss";
import "../assets/styles/fonts.css";

const Loader = (
  <div
    className="spinner-border"
    style={{ width: "3rem", height: "3rem" }}
    role="status"
  >
    <span className="sr-only">Loading...</span>
  </div>
);

export default function App() {
  return (
    <React.Suspense fallback={Loader}>
      <Router>
        <AuthProvider>
          <Switch>{renderRoutes(routes)}</Switch>
          <a
            href="https://wa.me/message/DI3WXWOU5R4OC1"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-whatsapp whatsapp-icon"></i>
          </a>
        </AuthProvider>
      </Router>
    </React.Suspense>
  );
}
