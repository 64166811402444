import { Row, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import CustomDatePicker from "../../../../components/CustomDatePicker/customDatePicker";
import { activeReservationsTabAtom } from "../../../../recoils/reservations";
import { filterReservation } from "../../../../utils/index";
import StatusHeaderContainer from "../../../../components/HeaderComponents/status_header_component";
import SearchHeaderComponent from "../../../../components/HeaderComponents/search_header_component";
import { searchAtom } from "../../../../recoils/helperAtoms";
import RoundedButton from "../../../../components/CustomButtons/RoundedButton";
import { PlusOutlined } from "@ant-design/icons";
import CommonModal from "../../../../components/Modal";
import { AddReservationFormModal } from "../addReservationModal";

export default function ReservationsDataTable(props) {
  const { columns, data, tableName, status, addNewReservation } = props;
  const [activeReservationTab, setActiveReservationsTab] = useRecoilState(
    activeReservationsTabAtom
  );
  const search = useRecoilValue(searchAtom);
  const [filterDate, setFilteredDate] = useState();
  const [isAddReservationModal, setIsAddReservationModal] =
    React.useState(false);
  const handleCancel = () => setIsAddReservationModal(false);
  const showAddResrvationModal = () => setIsAddReservationModal(true);

  return (
    <>
      <div id="dataTable">
        <SearchHeaderComponent title={tableName} searchField={true}>
          <Row justify="space-around">
            <RoundedButton onClick={showAddResrvationModal}>
              <PlusOutlined className="pb-1" style={{ color: "#FF5317" }} />
              <div className="inline-flex pl-2 font-bold">Add New</div>
            </RoundedButton>
          </Row>
        </SearchHeaderComponent>
        <StatusHeaderContainer>
          <h3 className="ml-5">Status :</h3>{" "}
          {status.map((name, index) => {
            return (
              <button
                key={index}
                className={[
                  name === activeReservationTab
                    ? "ring-0 ring-offset-2 text-red-800 bg-red-200 ring-red-100"
                    : "",
                  "inline-flex ml-5 my-5 items-center border text-s font-medium rounded px-2 py-0 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-white ",
                ]}
                onClick={() => setActiveReservationsTab(name)}
              >
                {name.toUpperCase().replaceAll("_", " ")}
              </button>
            );
          })}
          <CustomDatePicker value={filterDate} setValue={setFilteredDate} />
        </StatusHeaderContainer>
        <div className="bg-white px-5 py-5 pr-10">
          {data && (
            <Table
              columns={columns}
              scroll={{ y: "50vh" }}
              dataSource={filterReservation(
                data,
                search,
                filterDate,
                activeReservationTab
              )}
              className="rounded-sm shadow-lg py-0"
              pagination={{
                defaultPageSize: 8,
              }}
            />
          )}
        </div>
      </div>
      <CommonModal
        title="Add New Reservation"
        isModalOpen={isAddReservationModal}
        handleCancel={handleCancel}
        destroyOnClose={true}
      >
        <AddReservationFormModal
          onSubmit={addNewReservation}
          handleCancel={handleCancel}
        />
      </CommonModal>
    </>
  );
}
