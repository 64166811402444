/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useHistory } from "react-router-dom";

export default function (WrapperComponent, userRole) {
  function Authorization(props) {
    const history = useHistory();
    const authSelector = true;
    const {
      isAuthenticated,
      authUser: { roles },
    } = authSelector;

    const handleRedirect = React.useCallback(() => {
      if (!isAuthenticated || !roles?.some((item) => item === userRole)) {
        history.push("/");
      }
    }, [history, isAuthenticated, roles]);

    React.useEffect(() => {
      handleRedirect();
    }, [handleRedirect]);

    return isAuthenticated ? <WrapperComponent {...props} /> : "";
  }

  return Authorization;
}
