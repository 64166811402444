import { PlusOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useMutation, useQuery } from "react-query";
import CommonModal from "../../../components/Modal";
import { useAuth } from "../../../services/auth";
import {
  getAllCategories,
  getAllItems,
  updateCategory,
} from "../../../services/inventory";
import ItemForm from "./Item/ItemForm";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./style.scss";
import RoundedButton from "../../../components/CustomButtons/RoundedButton";
import CategoryCard from "./Category/CategoryCard";
import CategoryForm from "./Category/CategoryForm";
import ItemCard from "./Item/ItemCard";

export default function Inventory() {
  const { getIdToken } = useAuth();
  const token = getIdToken();

  const [isAddNewCategoryModalOpen, setAddNewCategoryModalVisible] =
    React.useState(false);
  const [isAddNewItemModalOpen, setAddNewItemModalOpen] = React.useState(false);

  const [categoryList, setCategoryList] = React.useState();
  const [activeCategory, setActiveCategory] = React.useState();

  const { mutate: updateFoodCategory } = useMutation(
    (data) => updateCategory(data),
    {
      onSuccess: (data) => {
        console.log("Updated Category Rank Successfully !!");
        // refetchCategory();
      },
    }
  );

  const { refetch: refetchCategory } = useQuery(
    "fetchAllCategory",
    () => getAllCategories(token),
    {
      onSuccess: (data) => {
        console.log("Fetched Categories Successfully !!");
        setCategoryList(data?.data?.data);
      },
    }
  );

  const {
    data: allItemsData,
    isSuccess: fetchedAllItems,
    refetch: refetchItems,
  } = useQuery("fetchAllItems", () => getAllItems(token));

  function addNewCategoryModal() {
    setAddNewCategoryModalVisible(true);
  }

  function showNewItemModal() {
    setAddNewItemModalOpen(true);
  }

  function handleCancel() {
    setAddNewCategoryModalVisible(false);
    setAddNewItemModalOpen(false);
  }

  const filteredItems = React.useMemo(() => {
    return allItemsData?.data?.data?.filter(
      (item) => item.ckey === activeCategory?.ckey
    );
  }, [activeCategory, allItemsData]);

  function handleOnDragEnd(result) {
    // console.log(result);
    const category = categoryList[result.draggableId];
    const body = {
      params: {
        ckey: category["ckey"],
        name: category["name"],
        oldRank: category["rank"],
        newRank: result.destination.index + 1,
      },
      token: token,
    };

    const items = Array.from(categoryList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCategoryList(items);
    updateFoodCategory(body);
  }

  // React.useEffect(() => {
  //   if (categoryDeletedSuccessfully && deleteCategoryData?.statusCode === 500) {
  //     toast.warning(deleteCategoryData?.message);
  //     refetchCategory();
  //   }
  // }, [categoryDeletedSuccessfully, deleteCategoryData, refetchCategory]);

  return (
    <>
      <div id="inventorySection">
        <Row>
          <Col span={6}>
            <div className="border-l border-b p-4">
              <div align="center" className="text-2xl font-semibold">
                Categories
              </div>
            </div>
            <div className="overflow-scroll" style={{ height: "83vh" }}>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="categories">
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {categoryList?.slice().map((category, index) => {
                        return (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <CategoryCard
                                refetchCategory={refetchCategory}
                                setActiveCategory={setActiveCategory}
                                activeCategory={activeCategory}
                                index={index}
                                category={category}
                                provided={provided}
                              />
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
              <div align="center" className="m-6">
                <RoundedButton onClick={addNewCategoryModal}>
                  <PlusOutlined className="pb-1" style={{ color: "#FF5317" }} />
                  <div className="inline-flex pl-2 font-bold">Add New</div>
                </RoundedButton>
              </div>
            </div>
          </Col>
          <Col span={18}>
            <Row
              justify="space-between"
              className="border-l border-b py-4 pl-6 pr-20"
            >
              <div className="text-2xl font-semibold">Items</div>
              <RoundedButton onClick={showNewItemModal}>
                <PlusOutlined className="pb-1" style={{ color: "#FF5317" }} />
                <div className="inline-flex pl-2 font-bold">Add New Item</div>
              </RoundedButton>
            </Row>
            <Row
              className="border-l border-b py-4 pl-6 pr-20 overflow-scroll"
              style={{ height: "83vh" }}
              align="top"
            >
              {activeCategory !== undefined &&
                fetchedAllItems &&
                filteredItems?.map((item, index) => {
                  return (
                    <ItemCard
                      key={index}
                      courseItem={item}
                      activeCategory={activeCategory}
                      refetchItems={refetchItems}
                    />
                  );
                })}
              {activeCategory === undefined && (
                <span className="text-lg text-red-500">
                  No category selected
                </span>
              )}
            </Row>
          </Col>
        </Row>
      </div>

      {/* ADD New Category Modal */}
      <CommonModal
        title="Add New Category"
        isModalOpen={isAddNewCategoryModalOpen}
        handleCancel={handleCancel}
      >
        <CategoryForm
          handleCancel={handleCancel}
          refetchCategory={refetchCategory}
        />
      </CommonModal>

      {/* ADD New Item Modal */}

      <CommonModal
        title="Add New Item"
        destroyOnClose={true}
        isModalOpen={isAddNewItemModalOpen}
        handleCancel={handleCancel}
      >
        <ItemForm
          handleCancel={handleCancel}
          refetchItems={refetchItems}
          activeCategory={activeCategory}
        />
      </CommonModal>
    </>
  );
}
