import moment from "moment";
import React from "react";
import { useRecoilValue } from "recoil";
import { takeAwayDataAtom } from "../../../recoils/takeaway";
import { calcTimeAgo } from "../../../utils/index";
import TakeAwayDataTable from "./TakeAwayDataTable";
import { Tag } from "antd";

export default function TakeAwayTable() {
  const takeAwayData = useRecoilValue(takeAwayDataAtom);

  const columns = [
    {
      title: "Order ID",
      key: "orderId",
      dataIndex: "orderId",
      width: 100,
      fixed: "left",
    },
    {
      title: "Ordered at",
      key: "createdAt",
      width: 200,
      render: (order) => (
        <>
          {moment(Date.parse(order?.createdAt)).format("DD MMM YY ") +
            " - " +
            calcTimeAgo(Date.parse(order?.createdAt))}
        </>
      ),
    },
    {
      title: "Type",
      width: 80,
      key: "type",
      render: (order) => (
        <Tag color={order?.serveOnSite ? "orange" : "blue"}>
          {order?.serveOnSite ? "SERVE" : "PACK"}
        </Tag>
      ),
    },
    {
      title: "Phone No",
      key: "phoneNo",
      dataIndex: "userPhonenumber",
      width: 150,
    },
    {
      title: "Items",
      key: "totalItems",
      dataIndex: "totalItems",
      width: 80,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 120,
      render: (status) => (
        <Tag
          color={
            status === "PENDING"
              ? "red"
              : status === "PREPARING"
              ? "gold"
              : status === "PREPARED"
              ? "magenta"
              : "green"
          }
        >
          <span className="">{status}</span>
        </Tag>
      ),
    },
  ];

  const orderStatus = ["PENDING", "PREPARING", "PREPARED", "COMPLETED"];

  return (
    <>
      <TakeAwayDataTable
        columns={columns}
        data={takeAwayData}
        tableName="Takeaway"
        status={orderStatus}
        isReservation={false}
      />
    </>
  );
}
