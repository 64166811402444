import React from "react";
import LineChart from "./charts/LineChart";

export default function Overview() {
  return (
    <div className="container mx-auto bg-white align-center shadow overflow-hidden sm:rounded-lg text-center">
		<h2 className="text-lg text-left px-10 py-10 leading-6 font-medium text-gray-900">Overview</h2>
      <div className="px-4 py-5 align-center items-center h-2/3 sm:px-6">
        {" "}
        <h3 className="text-lg leading-6 font-medium text-gray-900">Sales</h3>
        <div className="items center  " style={{ height: "300px" }}>
          <LineChart />
        </div>
      </div>
      <div className ="grid grid-cols-2 " >
        <div className="px-4 py-5 align-center items-center h-2/3 sm:px-6">
          {" "}
          <h3 className="text-lg leading-6 font-medium text-gray-900">Orders</h3>
          <div className="items center  " style={{ height: "300px" }}>
            <LineChart />
          </div>
        </div>

        <div className="px-4 py-5 align-center items-center h-2/3 sm:px-6">
          {" "}
          <h3 className="text-lg leading-6 font-medium text-gray-900">Profits</h3>
          <div className="items center  " style={{ height: "300px" }}>
            <LineChart />
          </div>
        </div>
      </div>
    </div>
  );
}
