import { UserOutlined } from "@ant-design/icons";
import { Badge, Col, Row, Table } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentTabIdAtom,
  liveOrderDataAtom,
  tabDataAtom,
  waiterRequestAtom,
} from "../../../../recoils/dinein";
import { invoiceViewAtom } from "../../../../recoils/helperAtoms";
import { orderDetailsByIdAtom } from "../../../../recoils/orders";
import { useAuth } from "../../../../services/auth";
import { getOrderDetailsById } from "../../../../services/orders";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OpenTabs(props) {
  const { columns, search } = props;

  const tabs = useRecoilValue(tabDataAtom);
  const liveOrderdata = useRecoilValue(liveOrderDataAtom);
  const [currentOrder, setCurrentOrder] = useState();
  const setOrderDetailsById = useSetRecoilState(orderDetailsByIdAtom);
  const [currentTab, setCurrentTab] = useRecoilState(currentTabIdAtom);
  const waiterRequstList = useRecoilValue(waiterRequestAtom);
  const { getIdToken } = useAuth();
  const setInvoiceView = useSetRecoilState(invoiceViewAtom);

  const token = getIdToken();

  const { mutate: selectedOrderDetailsMutation } = useMutation(
    (data) => getOrderDetailsById(data),
    {
      onSuccess: (res) => {
        console.log("Order Details fetched Successfully");
        setOrderDetailsById(res?.data?.data);
      },
    }
  );

  return (
    <>
      <Row justify="space-around">
        <Col span={6} className="">
          <nav
            className="space-y-1 mx-auto text-center py-5 w-100 cols-span-1"
            aria-label="Sidebar"
          >
            {tabs
              ?.filter((i) => i.tabOpen === true && i.status === "ACCEPTED")
              ?.map((item) => (
                <Badge
                  // dot={waiterRequstList?.find(
                  //   (request) =>
                  //     request?.restaurantId === item?.restaurantId &&
                  //     request?.status === "PENDING"
                  // )}
                  // style={{ padding: "5px" }}
                  count={
                    waiterRequstList?.find(
                      (request) =>
                        request?.tabId === item?.tabId &&
                        request?.status === "PENDING"
                    ) && (
                      <Avatar
                        className="bg-red-500"
                        size={20}
                        icon={<UserOutlined />}
                      />
                    )
                  }
                  color="red"
                >
                  <button
                    key={item.tabId}
                    className={classNames(
                      item.tabId === currentTab?.tabId
                        ? "bg-red-100 w-100 text-black-900 "
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group xl:flex items-center px-3 py-2 text-sm font-medium rounded-md shadow-md"
                    )}
                    onClick={() => {
                      setCurrentTab(item);
                      setInvoiceView(false);
                      setCurrentOrder();
                    }}
                    aria-current={
                      item.tabId === currentTab?.tabId ? "page" : undefined
                    }
                  >
                    <div className="xl:pb-0 xl:pl-0 pb-2">
                      {item.customTableId}
                    </div>
                    <div className="pl-4">
                      <span className="items-center px-2 mx-2 py-0.5 rounded-full text-xs font-medium bg-red-200 text-gray-800">
                        {
                          liveOrderdata?.filter(
                            (i) =>
                              i.tabId === item.tabId && i.status === "PENDING"
                          ).length
                        }
                      </span>

                      <span className="items-center px-2 mx-2 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-gray-800">
                        {
                          liveOrderdata?.filter(
                            (i) =>
                              i.tabId === item.tabId && i.status === "PREPARING"
                          ).length
                        }
                      </span>

                      <span className="items-center px-2 mx-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-gray-800">
                        {
                          liveOrderdata?.filter(
                            (i) =>
                              i.tabId === item.tabId && i.status === "COMPLETED"
                          ).length
                        }
                      </span>
                    </div>
                  </button>
                </Badge>
              ))}
            <div className="flex grid grid-cols-3 text-left py-10">
              <span className="col-span-1 inline-flex items-center px-1 mx-2 py-1  w-4 h-4 rounded-full text-xs font-medium bg-red-300 text-green-300"></span>{" "}
              <span className="col-span-2">Pending</span>
              <span className="col-span-1 inline-flex items-center px-1 mx-2 py-1 w-4 h-4 rounded-full text-xs font-medium bg-yellow-300 text-yellow-300"></span>{" "}
              <span className="col-span-2">Preparing</span>
              <span className="col-span-1 inline-flex items-center px-1 mx-2 py-1  w-4 h-4  rounded-full text-xs font-medium bg-green-300 text-green-300"></span>{" "}
              <span className="col-span-2">Completed</span>
            </div>
          </nav>
        </Col>
        <Col span={16}>
          <div className="bg-white ">
            <Table
              style={{ cursor: "pointer" }}
              columns={columns}
              scroll={{ y: "50vh" }}
              className="colspan-3 px-2 rounded-sm shadow-lg py-0"
              rowClassName={(record, index) =>
                record.orderId === currentOrder ? "bg-gray-100" : "bg-white"
              }
              dataSource={
                liveOrderdata
                  ? search
                    ? liveOrderdata?.filter(
                        (i) =>
                          i.tabId === currentTab?.tabId &&
                          i.orderId.toLowerCase().includes(search.toLowerCase())
                      )
                    : liveOrderdata.filter((i) => i.tabId === currentTab?.tabId)
                  : null
              }
              onRow={(record, _rowIndex) => {
                return {
                  onClick: () => {
                    setCurrentOrder(record.orderId);
                    selectedOrderDetailsMutation({
                      orderId: record.orderId,
                      token: token,
                    });
                  },
                };
              }}
              pagination={{
                defaultPageSize: 10,
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
