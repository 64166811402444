import { Table } from "antd";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import CustomDatePicker from "../../../../components/CustomDatePicker/customDatePicker";
import SearchHeaderComponent from "../../../../components/HeaderComponents/search_header_component";
import StatusHeaderContainer from "../../../../components/HeaderComponents/status_header_component";
import { invoiceViewAtom, searchAtom } from "../../../../recoils/helperAtoms";
import {
  currentDeliveryStatusTabAtom,
  deliveryDataAtom,
  deliveryDetailsByIdAtom,
} from "../../../../recoils/delivery";
import { useAuth } from "../../../../services/auth";
import {
  getAllDelivery,
  getDeliveryDetailsById,
} from "../../../../services/delivery";
import { filterForDeliveryTable } from "../../../../utils";

export default function DeliveryDataTable(props) {
  const { columns, tableName, status } = props;
  const search = useRecoilValue(searchAtom);
  const [currentStatus, setCurrentStatus] = useRecoilState(
    currentDeliveryStatusTabAtom
  );
  const [currentOrder, setCurrentOrder] = useState("");
  const { getIdToken } = useAuth();
  const token = getIdToken();
  const [filterDate, setFilteredDate] = useState();
  const setDeliveryDetailsById = useSetRecoilState(deliveryDetailsByIdAtom);
  const [data, setDeliveryData] = useRecoilState(deliveryDataAtom);
  const setInvoiceView = useSetRecoilState(invoiceViewAtom);

  const { mutate: deliveryMutation } = useMutation(
    "fetchAllDeliveryData",
    (pageNo) => {
      return getAllDelivery(token, pageNo);
    },
    {
      onSuccess: (data) => {
        console.log("fetched deliveries Successfully");
        setDeliveryData(data?.data?.data);
      },
    }
  );

  const { mutate: selectedDeliveryDetailsMutation } = useMutation(
    (data) => getDeliveryDetailsById(data),
    {
      onSuccess: (data) => {
        console.log("fetched details of selected delivery Successfully");
        setDeliveryDetailsById(data?.data?.data);
      },
    }
  );

  function handlePagination(pageNo) {
    deliveryMutation(pageNo - 1);
  }

  return (
    <div id="dataTable">
      <SearchHeaderComponent title={tableName} searchField={true} />
      <StatusHeaderContainer>
        <h3 className="ml-5">Status :</h3>
        <button
          key={"ALL"}
          className={[
            currentStatus === undefined
              ? "ring-0 ring-offset-2 text-red-800 bg-red-200 ring-red-100 "
              : "text-red-400",
            "inline-flex  ml-5 items-center border text-s font-medium rounded px-2 py-0 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-white ",
          ]}
          onClick={() => setCurrentStatus(undefined)}
        >
          {"ALL"}
        </button>

        {status.map((name, index) => {
          return (
            <button
              key={index}
              className={[
                currentStatus === name
                  ? "ring-0 ring-offset-2 text-red-800 bg-red-200 ring-red-100"
                  : "",
                "inline-flex ml-5 my-2 items-center border text-s font-medium rounded px-2 py-0 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-white ",
              ]}
              onClick={() => setCurrentStatus(name)}
            >
              {name.replaceAll("_", " ")}
            </button>
          );
        })}
        <CustomDatePicker value={filterDate} setValue={setFilteredDate} />
      </StatusHeaderContainer>
      <div className="bg-white px-10 pt-10">
        <Table
          columns={columns}
          scroll={{ y: "50vh" }}
          dataSource={filterForDeliveryTable(
            data?.response,
            search,
            currentStatus,
            filterDate
          )}
          rowClassName={(record, index) =>
            record.deliveryId === currentOrder ? "bg-gray-100" : "bg-white"
          }
          className="px-2 rounded-sm shadow-lg py-0  "
          onRow={(record, _rowIndex) => {
            return {
              onClick: () => {
                selectedDeliveryDetailsMutation({
                  deliveryId: record.deliveryId,
                  token: token,
                });
                setCurrentOrder(record.deliveryId);
                setInvoiceView(false);
              },
            };
          }}
          pagination={{
            onChange: handlePagination,
            total: data?.total,
          }}
        />
      </div>
    </div>
  );
}
