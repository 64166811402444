import {
  CaretRightOutlined,
  CheckOutlined,
  QqCircleFilled,
  RightOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Carousel, Col, Row } from "antd";
import jwt_decode from "jwt-decode";
import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import Typewriter from "typewriter-effect";
import landing1 from "../../assets/images/landing/landing1.png";
import landing10 from "../../assets/images/landing/landing10.png";
import landing2 from "../../assets/images/landing/landing2.png";
import landing3 from "../../assets/images/landing/landing3.png";
import landing4 from "../../assets/images/landing/landing4.png";
import landing5 from "../../assets/images/landing/landing5.png";
import landing6 from "../../assets/images/landing/landing6.png";
import landing7 from "../../assets/images/landing/landing7.png";
import landing8 from "../../assets/images/landing/landing8.png";
import landing9 from "../../assets/images/landing/landing9.png";
import userInteraction from "../../assets/images/landing/user-interaction.png";
import LogoH from "../../assets/images/logo-horizontal.png";
import business from "../../assets/images/poweredBy/business.png";
import london from "../../assets/images/poweredBy/london.png";
import sydney from "../../assets/images/poweredBy/sydeney.png";
import thePrint from "../../assets/images/poweredBy/the-print.png";
import zee from "../../assets/images/poweredBy/zee5.png";
import thumbnail from "../../assets/images/videoThumbnail.png";
import { ReactComponent as TakeawayIcon } from "../../assets/svgs/bag.svg";
import { ReactComponent as DineInIcon } from "../../assets/svgs/git-pull-request.svg";
import { ReactComponent as DeliveryIcon } from "../../assets/svgs/truck.svg";
import PrimaryButton from "../../components/CustomButtons/PrimaryButton";
import TestimonialComponent from "../../components/testimonial";
import { useAuth } from "../../services/auth";
import "./style.scss";
import { useSpring, animated, easings } from "react-spring";
import Typography from '@mui/material/Typography';

export default function Landing() {
  const history = useHistory();
  const { signInWithGoogle, userProfile } = useAuth();
  const [playing, setPlaying] = useState(false);
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(0);
  const slider = useRef();

  const testimonials = [
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnSUiWGpbsahtz4NMQPdFXTuUX5sLG20zxSA&usqp=CAU",
      name: "Leslie Alexander",
      review:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor doamet sint. Velit officia consequat duis enim velit mollit.Exercitation veniam consequat sunt nostrud amet.",
      designation: "Marketing Executive",
      restaurant: "STARS WARS CAFE",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnSUiWGpbsahtz4NMQPdFXTuUX5sLG20zxSA&usqp=CAU",
      name: "Leslie Alexander",
      review:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor doamet sint. Velit officia consequat duis enim velit mollit.Exercitation veniam consequat sunt nostrud amet.",
      designation: "Marketing Executive",
      restaurant: "STARS WARS CAFE",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnSUiWGpbsahtz4NMQPdFXTuUX5sLG20zxSA&usqp=CAU",
      name: "Leslie Alexander",
      review:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor doamet sint. Velit officia consequat duis enim velit mollit.Exercitation veniam consequat sunt nostrud amet.",
      designation: "Marketing Executive",
      restaurant: "STARS WARS CAFE",
    },
  ];

  const events = [
    {
      img: "https://images.unsplash.com/photo-1565958011703-44f9829ba187?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=930&q=80",
      type: "FOOD",
      title: "Grown-Up Spins on Your Favorite Childhood Foods",
    },
    {
      img: "https://images.unsplash.com/photo-1646281969261-5cab02226e7b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDM2fHduenBMeHMwblFZfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      type: "Place",
      title: "Things to know about Singapore Bicentennial",
    },
    {
      img: "https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80",
      type: "LOCAL",
      title: "Journey to the past: Historical sites of SG",
    },
    {
      img: "https://images.unsplash.com/photo-1646281969261-5cab02226e7b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDM2fHduenBMeHMwblFZfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      type: "Place",
      title: "Things to know about Singapore Bicentennial",
    },
  ];

  const typeWriterText = [
    "Restaurant's",
    "Cafe's",
    "Bar's",
    "Diner's",
    "Coffee Shop's",
    "Takeaway Shop's",
    "Club's",
  ];

  const afterChange = (current) => {
    setActiveTestimonialIndex(current);
  };

  React.useEffect(() => {
    if (userProfile) {
      const decodedJWT = jwt_decode(userProfile?.Aa);
      localStorage.setItem("flunkeyAuthToken", `Bearer ${userProfile?.Aa}`);
      if (decodedJWT?.store) {
        history.push("/admin");
        localStorage.setItem("flunkeyMerchantStore", decodedJWT?.store);
      } else {
        history.push("/register");
      }
    }
  }, [history, userProfile]);

  function useIntersectionObserver(
    elementRef,
    { threshold = 0, root = null, rootMargin = "0%", freezeOnceVisible = false }
  ) {
    const [entry, setEntry] = useState();

    const frozen = entry?.isIntersecting && freezeOnceVisible;

    const updateEntry = ([entry]) => {
      setEntry(entry);
    };

    React.useEffect(() => {
      const node = elementRef?.current;
      const hasIOSupport = !!window.IntersectionObserver;

      if (!hasIOSupport || frozen || !node) return;

      const observerParams = { threshold, root, rootMargin };
      const observer = new IntersectionObserver(updateEntry, observerParams);

      observer.observe(node);

      return () => observer.disconnect();
    }, [elementRef, threshold, root, rootMargin, frozen]);

    return entry;
  }

  const triggerRef1 = useRef();
  const triggerRef2 = useRef();
  const triggerRef3 = useRef();
  const triggerRef4 = useRef();
  const triggerRef5 = useRef();
  const dataRef1 = useIntersectionObserver(triggerRef1, {
    freezeOnceVisible: false,
  });
  const dataRef2 = useIntersectionObserver(triggerRef2, {
    freezeOnceVisible: false,
  });
  const dataRef3 = useIntersectionObserver(triggerRef3, {
    freezeOnceVisible: false,
  });
  const dataRef4 = useIntersectionObserver(triggerRef4, {
    freezeOnceVisible: false,
  });
  const dataRef5 = useIntersectionObserver(triggerRef5, {
    freezeOnceVisible: false,
  });

  const animation1 = useSpring({
    config: { duration: 800 },
    from: { opacity: 0, y: 300 },
    to: {
      opacity: dataRef1?.isIntersecting ? 1 : 0,
      y: dataRef1?.isIntersecting ? 0 : 300,
    },
  });
  const animation2 = useSpring({
    config: { duration: 800 },
    from: { opacity: 0, y: 300 },
    to: {
      opacity: dataRef2?.isIntersecting ? 1 : 0,
      y: dataRef2?.isIntersecting ? 0 : 300,
    },
  });
  const animation3 = useSpring({
    config: { duration: 800 },
    from: { opacity: 0, y: 300 },
    to: {
      opacity: dataRef3?.isIntersecting ? 1 : 0,
      y: dataRef3?.isIntersecting ? 0 : 300,
    },
  });
  const animation4 = useSpring({
    config: { duration: 800 },
    from: { opacity: 0, y: 300 },
    to: {
      opacity: dataRef4?.isIntersecting ? 1 : 0,
      y: dataRef4?.isIntersecting ? 0 : 300,
    },
  });
  const animation5 = useSpring({
    config: { duration: 800 },
    from: { opacity: 0, y: 200 },
    to: {
      opacity: dataRef5?.isIntersecting ? 1 : 0,
      y: dataRef5?.isIntersecting ? 0 : 200,
    },
  });

  return (
    <>
      <section style={{ background: "#F9F9F9" }}>
        <div
          className="text-4xl font-semibold text-center pt-40 tracking-wider"
          style={{ lineHeight: "50px" }}
        >
          More Orders, Customers, Money at <br /> Zero investment with{" "}
          <span style={{ color: "#ff8d0b" }}>Flunkey</span>
        </div>
        <div
          className="text-center pt-5 text-lg font-medium"
          style={{ color: "#3F4C6B" }}
        >
          Manage complete{" "}
          <span
            className="bg-white p-2 mx-4 rounded-md"
            style={{
              boxShadow: "0px 0px 8px 2px #0000000D",
            }}
          >
            <div className="inline-block align-middle gradient-reverse bg-clip-text">
              <Typewriter
                options={{
                  strings: typeWriterText,
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
          </span>{" "}
          digital experience
        </div>
        <div className="text-center p-16">
          <PrimaryButton
            className="gradient-reverse px-16"
            text="REGISTER NOW"
            onClick={signInWithGoogle}
          />
        </div>
        <div className="flex justify-center items-center p-10">
          <ReactPlayer
            className="m-auto"
            height={560}
            width={996}
            url="https://youtu.be/Vjwq1zLksPw"
            controls
            light={thumbnail}
            playing={playing}
            playIcon={
              <div className="bg-white absolute p-2 pr-4 rounded-lg shadow-md font-semibold">
                <CaretRightOutlined
                  style={{ color: "#ff5317", fontSize: "25px" }}
                />
                <span className="bg-clip-text gradient-reverse">
                  Watch Intro
                </span>
              </div>
            }
            onClickPreview={(e) => {
              console.log(e);
              setPlaying(!playing);
            }}
          />
        </div>
      </section>

      <section
        className="bg-white flex flex-col items-center p-5"
        style={{ color: "#95A4C7" }}
      >
        <div className="font-medium text-xl leading-8 tracking-widest text-center">
          Power your Store with Flunkey
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <img src={LogoH} height={24} width={130} />
          </span>
        </div>
        {/* <Row className=" pt-4 " justify="center">
          <img className="px-2" src={zee} />
          <img className="px-2" src={sydney} />
          <img className="px-2" src={business} />
          <img className="px-2" src={thePrint} />
          <img className="px-2" src={london} />
        </Row> */}
      </section>

      <section
        id="product"
        style={{ background: "#F9F9F9" }}
        className="bg-white flex flex-col items-center py-16"
      >
        <div
          className="text-2xl tracking-wider font-semibold pt-4"
          style={{ lineHeight: "60px" }}
        >
          An all-in-one solution <span style={{ color: "#FF5317" }}>.</span>{" "}
        </div>
        <div
          className="text-sm tracking-widest font-medium leading-7"
          style={{ color: "#3F4C6B" }}
        >
          Flunkey puts everything you need to go online — from <br />
          accepting orders to delivery — right at your fingertips.
        </div>
        <Row className="items-end justify-center m-14">
          <Col>
            <animated.div
              ref={triggerRef1}
              style={{
                background: "#F4E9E9",
                width: "488px",
                height: "511px",
                ...animation1,
              }}
              className="p-8 m-3 tracking-wider rounded-xl"
            >
              <div
                className="text-xl font-semibold"
                style={{ lineHeight: "60px" }}
              >
                Manage Inventory
              </div>
              <div
                className="text-lg font-medium leading-8"
                style={{ color: "#3F4C6B" }}
              >
                Automated Planning
                <br /> Stock Alerts
              </div>
              <div>
                <img
                  className="absolute z-10 mt-8"
                  width={174}
                  height={214}
                  src={landing1}
                />
                <img
                  className="absolute z-0 mt-40 ml-10"
                  height={145}
                  width={347}
                  src={landing2}
                />
                <img
                  className="absolute z-10 mt-20 ml-64"
                  height={95}
                  width={162}
                  src={landing3}
                />
              </div>
            </animated.div>

            <animated.div
              ref={triggerRef2}
              style={{
                background: "#F4F0E9",
                width: "488px",
                height: "551px",
                ...animation2,
              }}
              className="p-8 m-3 mt-6 tracking-wider rounded-xl"
            >
              <div className="flex justify-center">
                <img
                  className="absolute z-10 mt-16 mr-48"
                  width={183}
                  height={183}
                  src={landing4}
                />
                <img
                  className="absolute z-0 ml-32"
                  height={380}
                  width={208}
                  src={landing5}
                />
                <div className="gradient-reverse p-2 flex flex-col items-center z-20 rounded-xl text-xs font-medium mt-60 ml-80">
                  <img height={18} width={23} className="pb-1" src={landing6} />
                  Delivery
                </div>
              </div>
              <div
                className="text-xl font-semibold mt-6"
                style={{ lineHeight: "60px" }}
              >
                Self Delivery
              </div>
              <div
                className="text-lg font-medium leading-8"
                style={{ color: "#3F4C6B" }}
              >
                Add delivery fleet
                <br /> Live updates
                <br />
                Digital Reciepts
              </div>
            </animated.div>
          </Col>
          <Col>
            <animated.div
              ref={triggerRef3}
              style={{
                background: "#E9EFF4",
                width: "488px",
                height: "671px",
                ...animation3,
              }}
              className="p-8 m-3 tracking-wider rounded-xl"
            >
              <div>
                <img
                  className="absolute z-10 mt-14"
                  height={164}
                  width={210}
                  src={landing7}
                />
                <img
                  className="absolute z-0 ml-14"
                  height={295}
                  width={296}
                  src={landing8}
                />
                <div
                  className="absolute font-semibold text-center"
                  style={{ margin: "100px 0 0 300px" }}
                >
                  <div className="p-4 rounded-full relative shadow-xl bg-white mb-2">
                    <DineInIcon
                      className="icon-color"
                      style={{ height: "50px", width: "50px" }}
                    />
                  </div>
                  <span className="mt-20">Dine In</span>
                </div>
                <div
                  className="absolute font-semibold text-center"
                  style={{ margin: "240px 0 0 250px" }}
                >
                  <div className="p-4 rounded-full relative shadow-xl bg-white mb-2">
                    <TakeawayIcon
                      className="icon-color"
                      style={{ height: "40px", width: "50px" }}
                    />
                  </div>
                  <span className="mt-20">Takeaway</span>
                </div>
                <div
                  className="absolute font-semibold text-center"
                  style={{ margin: "290px 0 0 120px" }}
                >
                  <div className="p-4 rounded-full  shadow-xl bg-white mb-2">
                    <DeliveryIcon
                      className="icon-color "
                      style={{ height: "30px", width: "50px" }}
                    />
                  </div>
                  <span className="mt-48 relative">Delivery</span>
                </div>
              </div>
              <div style={{ marginTop: "410px" }}>
                <div
                  className="text-xl font-semibold"
                  style={{ lineHeight: "35px" }}
                >
                  Accept and process orders and Reservations
                  <br />
                </div>
                <div
                  className="text-lg font-medium leading-8 mt-4"
                  style={{ color: "#3F4C6B" }}
                >
                  Dine In
                  <br /> Takeaway
                  <br /> Delivery
                </div>
              </div>
            </animated.div>

            <animated.div
              ref={triggerRef4}
              style={{
                background: "#F4E9E9",
                width: "488px",
                height: "442px",
                ...animation4,
              }}
              className="p-8 m-3 mt-6 tracking-wider rounded-t-xl mb-16"
            >
              <div>
                <div
                  className="text-xl font-semibold"
                  style={{ lineHeight: "35px" }}
                >
                  Customer Engagement
                  <br />
                </div>
                <div
                  className="text-lg font-medium leading-8 mt-4"
                  style={{ color: "#3F4C6B" }}
                >
                  Run Offers
                  <br /> Loyalty
                  <br /> Programs <br />
                  Events <br />
                  Customise offer anytime
                </div>
              </div>
              <div>
                <img
                  className="absolute z-10 mt-8 ml-12"
                  width={250}
                  height={120}
                  src={landing9}
                />
                <img
                  className="absolute z-0 ml-56"
                  style={{ marginTop: "-115px" }}
                  height={300}
                  width={220}
                  src={landing10}
                />
              </div>
            </animated.div>
            <animated.div
              ref={triggerRef5}
              style={{ width: "488px", height: "168px", ...animation5 }}
              className="p-8 m-3 gradient-reverse tracking-wider rounded-xl"
            >
              <div className="text-white text-xl font-semibold">
                Start managing your outlet with Zero Investment.
              </div>
              <button
                onClick={signInWithGoogle}
                className="bg-white rounded-md text-black text-semibold p-2 px-6 mt-3"
              >
                GET STARTED
              </button>
            </animated.div>
          </Col>
        </Row>
      </section>

      <section className="p-8 text-center bg-white">
        <div
          className="text-2xl tracking-wider font-semibold pt-4"
          style={{ lineHeight: "50px" }}
        >
          Promote Your Upcoming Events
          <br /> in Singapore
        </div>
        {/* <div
          className="text-sm tracking-widest font-medium leading-7"
          style={{ color: "#3F4C6B" }}
        >
          Amet minim mollit non deserunt ullamco.
        </div> */}
        <Row justify="space-around" className="pt-16">
          {events.map((event, index) => (
            <div
              key={index}
              className="pb-4"
              style={{
                width: "285px",
              }}
            >
              <img
                src={event.img}
                className="rounded-lg w-full"
                style={{
                  height: "165px",
                  objectFit: "cover",
                }}
              />
              <div
                className="text-sm tracking-widest text-left font-medium leading-10"
                style={{ color: "#3F4C6B" }}
              >
                {event.type}
              </div>
              <div className="text-xl text-left tracking-wider font-semibold ">
                {event.title}
              </div>
            </div>
          ))}
        </Row>
        <button
          className="gradient-reverse bg-clip-text py-2 px-6 mt-16 rounded-lg border-2"
          style={{ borderColor: "#FF5317" }}
          onClick={()=>{window.open("https://forms.gle/8dmBWbQmwnNnK4Ec7","_blank");}}
        >
          Promote Now
        </button>
      </section>

      <section style={{ background: "#F4F0E9" }} className="p-16">
        <Row justify="space-around" className="md:flex-row-reverse">
          <Col>
            <img src={userInteraction} height={490} width={446} />
          </Col>
          <Col>
            <div
              className="text-3xl font-semibold"
              style={{ lineHeight: "50px" }}
            >
              Zero investment and hassle
              <br /> free way to go digital.
            </div>
            <div
              className="text-lg font-medium leading-8"
              style={{ color: "#3F4C6B" }}
            >
              Get up and running in just a few simple steps.
            </div>
            <div className="pt-6">
              <div className="text-xl my-6 font-medium align-bottom">
                <CheckOutlined className="pr-4" style={{ color: "#FF5317" }} />{" "}
                Digital Restaurant at Zero Cost
              </div>
              <div className="text-xl my-6 font-medium align-bottom">
                <CheckOutlined className="pr-4" style={{ color: "#FF5317" }} />{" "}
                Pay as you go
              </div>
              <div className="text-xl my-6 font-medium align-bottom">
                <CheckOutlined className="pr-4" style={{ color: "#FF5317" }} />{" "}
                All in one Platform
              </div>
              <div className="text-xl my-6 font-medium align-bottom">
                <CheckOutlined className="pr-4" style={{ color: "#FF5317" }} />{" "}
                Data Analytics
              </div>
            </div>
          </Col>
        </Row>
      </section>

      <section id="pricing" style={{ background: "#F9F9F9" }} className="p-16">
        <div
          className="bg-white rounded-md shadow-xl m-auto"
          style={{ maxWidth: "692px" }}
        >
          <Row align="stretch pt-10 px-8">
            <Col lg={16} sm={24}>
              <div className="font-semibold pb-2">Pricing</div>
              <div className="gradient-reverse bg-clip-text text-4xl font-semibold">
                All services <br />
                are Free
              </div>
              <div
                className="text-sm my-6 align-bottom"
                style={{ color: "#3F4C6B" }}
              >
                <div className="my-2">
                  <CheckOutlined
                    className="pr-4"
                    style={{ color: "#FF5317" }}
                  />{" "}
                  Open a tab using a table QR code menu
                </div>
                <div className="my-2">
                  <CheckOutlined
                    className="pr-4"
                    style={{ color: "#FF5317" }}
                  />{" "}
                  Order processing at dine-in
                </div>
                <div className="my-2">
                  <CheckOutlined
                    className="pr-4"
                    style={{ color: "#FF5317" }}
                  />{" "}
                  Order Processing for takeaways
                </div>
                <div className="my-2">
                  <CheckOutlined
                    className="pr-4"
                    style={{ color: "#FF5317" }}
                  />{" "}
                  Order processing for self-delivery
                </div>
                <div className="my-2">
                  <CheckOutlined
                    className="pr-4"
                    style={{ color: "#FF5317" }}
                  />{" "}
                  Free Event Promotion on flunkey
                </div>
              </div>
            </Col>
            <Col
              span={8}
              style={{ color: "#95A4C7" }}
              className="font-semibold flex flex-col-reverse align-items-center"
            >
              <div>
                <span style={{ fontSize: "250px", lineHeight: "1" }}>0</span>
                <span className="text-2xl">$</span>
              </div>
            </Col>
          </Row>
          <div
            className="text-sm my-6 align-bottom p-8"
            style={{ color: "#3F4C6B", background: "#F4F0E9" }}
          >
            <div className="text-xl tracking-wider font-semibold pb-2">
              You only pay as you use
            </div>
            <div className="my-2">
              <span
                style={{ color: "#FF5317" }}
                className="text-xl font-bold pr-4"
              >
                ●
              </span>
              Bank transaction Fees: 3.49% - 3.99% on cards and 2% for wallet
            </div>
            <div className="my-2">
              <span
                style={{ color: "#FF5317" }}
                className="text-xl font-bold pr-4"
              >
                ●
              </span>
              Table Reservation: 5% of Gross Bill per reservation order
            </div>
            <div className="mt-5">
             <Typography variant="caption">*Flunkey reserves the right to change pricing</Typography>
            </div>
          </div>
        </div>

        {/* <div className="p-8 m-8 w-96 bg-white rounded-xl border-2">
            <div style={{ color: "#3F4C6B" }}>Others</div>
            <div className="text-3xl tracking-wider font-semibold py-4">
              $ 230 <span className="text-sm font-medium">minimum</span>
            </div>
            <div className="text-sm tracking-widest font-medium leading-7">
              Amet minim mollit non deserunt ullamco est sit aliqua.
            </div>
            <div
              className="text-sm my-6 font-medium align-bottom"
              style={{ color: "#3F4C6B" }}
            >
              <div className="my-2">
                <StopOutlined className="pr-4" style={{ color: "#FF5317" }} />{" "}
                Stuck with one time payment.
              </div>
              <div className="my-2">
                <StopOutlined className="pr-4" style={{ color: "#FF5317" }} />{" "}
                Stuck with one time payment.
              </div>
              <div className="my-2">
                <StopOutlined className="pr-4" style={{ color: "#FF5317" }} />{" "}
                Hidden charges
              </div>
            </div>
          </div> */}

        <div className="text-center p-8">
          <PrimaryButton
            className="gradient-reverse px-16"
            text="REGISTER WITH FLUNKEY"
            onClick={signInWithGoogle}
          />
        </div>
      </section>

      {/* <section className="p-16 w-full bg-white">
        <Carousel
          autoplay
          dots={false}
          afterChange={afterChange}
          ref={(ref) => {
            slider.current = ref;
          }}
        >
          {testimonials.map((testimonial, index) => (
            <TestimonialComponent testimonial={testimonial} key={index} />
          ))}
        </Carousel>
        <ul className="justify-center flex">
          {testimonials.map((_, index) => (
            <button
              onClick={() => {
                console.log(index);
                slider.current.goTo(index);
              }}
              style={{
                color: `${
                  index == activeTestimonialIndex ? "#FF5317" : "#CED7EB"
                }`,
              }}
              className={`text-2xl px-1`}
            >
              •
            </button>
          ))}
        </ul>
      </section> */}
    </>
  );
}
