import Axios from "axios";
import { axiosInitialParams } from "../../utils";

const baseAPIUrl = process.env.REACT_APP_API_SERVER;
const axios = Axios.create({
  baseURL: baseAPIUrl,
  axiosInitialParams,
});

// return all Live Orders
export const getAllLiveOrders = async (token) => {
  token = await token;
  return axios.get("/merchant/liveOrders", {
    headers: { Authorization: "Bearer " + token },
  });
};

// returns details of order by orderId
export const getOrderDetailsById = async ({ orderId, token }) => {
  token = await token;
  return axios.get(`/merchant/liveOrders/${orderId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};
