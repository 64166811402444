import { Col, Row } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  currentTabIdAtom,
  liveOrderDataAtom,
  tabDataAtom,
  waiterRequest,
  waiterRequestAtom,
} from "../../../recoils/dinein";
import { searchAtom } from "../../../recoils/helperAtoms";
import { useAuth } from "../../../services/auth";
import { getTabs } from "../../../services/dinein";
import { getAllLiveOrders } from "../../../services/orders";
import { getActiveWaiterRequest } from "../../../services/waiterRequest";
import DineInOrderDetails from "./DineInOrderDetails";
import DineInTable from "./DineInTable";

export default function DineIn() {
  const { getIdToken } = useAuth();

  const token = getIdToken();
  const setTabData = useSetRecoilState(tabDataAtom);
  const setLiveOrdersData = useSetRecoilState(liveOrderDataAtom);
  const setWaiterRequests = useSetRecoilState(waiterRequestAtom);
  const setSearch = useSetRecoilState(searchAtom);
  const [currentTab, setCurrentTab] = useRecoilState(currentTabIdAtom);

  const { refetch: refetchTabs } = useQuery(
    "getTabs",
    () => getTabs({ token, paid: undefined, status: undefined }),
    {
      onSuccess: (res) => {
        console.log("Tabs fetched Successfully");
        setTabData(
          res?.data?.data
            ?.slice()
            .sort((a, b) => b.createdAt._seconds - a.createdAt._seconds)
        );
        setCurrentTab(
          res?.data?.data?.find((tab) => tab.tabId === currentTab?.tabId)
        );
      },
    }
  );

  const { refetch: refetchLiveOrders } = useQuery(
    "fetchedLiveOrders",
    () => getAllLiveOrders(token),
    {
      onSuccess: (res) => {
        console.log("Live Orders fetched Successfully");
        setLiveOrdersData(
          res?.data?.data
            ?.slice()
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
      },
    }
  );

  const { refetch: refetchWaiterRequest } = useQuery(
    "fetchWaiterRequest",
    () => getActiveWaiterRequest(token),
    {
      onSuccess: (res) => {
        console.log("Waiter Request fetched Successfully");
        setWaiterRequests(res?.data?.data);
      },
    }
  );

  React.useEffect(() => setSearch());

  return (
    <div id="dineIn">
      <Row>
        <Col span={16}>
          <Col>
            <DineInTable />
          </Col>
        </Col>
        <Col span={8}>
          <DineInOrderDetails
            refetchTabs={refetchTabs}
            refetchLiveOrders={refetchLiveOrders}
            refetchWaiterRequest={refetchWaiterRequest}
          />
        </Col>
      </Row>
    </div>
  );
}
