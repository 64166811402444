import { CheckOutlined, CloseOutlined, EditFilled } from '@ant-design/icons'
import { Col, Popconfirm, Row, Switch } from 'antd'
import clsx from 'clsx'
import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import CommonModal from '../../../../components/Modal'
import { useAuth } from '../../../../services/auth'
import {
  deleteInventoryItem,
  updateInventoryItem,
} from '../../../../services/inventory'
import ItemForm from './ItemForm'

export default function ItemCard(props) {
  const { courseItem, refetchItems, activeCategory } = props
  const { getIdToken } = useAuth()
  const token = getIdToken()
  const [isUpdateItemModalOpen, setUpdateItemModalVisible] = React.useState(
    false,
  )

  const { mutate: deleteInventoryItemMutation } = useMutation(
    (data) => deleteInventoryItem(data),
    {
      onSuccess: () => {
        toast.success('You have sucessfully deleted Item')
        console.log('You have sucessfully deleted Item')
        refetchItems()
      },
    },
  )

  //
  const { mutate: editItemAvailablityMutation } = useMutation(
    (data) => updateInventoryItem(data),
    {
      onSuccess: () => {
        console.log('Item Availablity updated Succesfully')
        refetchItems()
      },
    },
  )

  function showUpdateItemModal() {
    setUpdateItemModalVisible(true)
  }

  function handleCancel() {
    setUpdateItemModalVisible(false)
  }

  function handleDeleteCategory(itemId) {
    const body = {
      params: {
        id: itemId,
      },
      token: token,
    }
    deleteInventoryItemMutation(body)
  }

  function onSwitchChange(isAvailable) {
    const query = {
      params: {
        id: courseItem.id,
        name: courseItem.name,
        description: courseItem.description,
        price: courseItem.price,
        units: courseItem.units,
        available: isAvailable,
        imageRaw: courseItem.imageRaw,
      },
      token: token,
    }

    editItemAvailablityMutation(query)
  }

  return (
    <>
      <div
        className="bg-white-100 shadow-md p-2 rounded-md w-48 m-4"
        style={{ height: 260 }}
      >
        <img
          src={courseItem.url}
          alt="courseItemImage"
          className="item-image object-contain w-3/4 m-auto"
        />
        <div className="text-md text-center font-bold py-2">
          {courseItem.name}
        </div>

        <Row justify="space-around" align="middle">
          <Col span={12} align="center">
            <div className="pb-2">
              Price <span className=" font-bold">{courseItem.price}</span>
            </div>
            {/* <span
              className={clsx(
                courseItem.available
                  ? "available-symbol bg-green-100  text-green-800 px-2 py-1"
                  : "unavailable-symbol bg-red-100 text-red-800 px-2 py-1"
              )}
            >
              {courseItem.available ? "Available" : "Unavailable"}
            </span> */}
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={courseItem?.available}
              onChange={onSwitchChange}
            />
          </Col>

          <Col span={12} align="center">
            <div className="pb-2">
              Qty <span className="font-bold">{courseItem.units}</span>
            </div>
            <button className="edit-course-item">
              <Popconfirm
                title="Are you sure to perform this actions?"
                onConfirm={() => handleDeleteCategory(courseItem.id)}
                onCancel={showUpdateItemModal}
                okText="Delete"
                cancelText="Edit"
              >
                <EditFilled className="edit-icon pr-2" />
                Edit
              </Popconfirm>
            </button>
          </Col>
        </Row>
      </div>

      {/* Edit Item Modal */}
      <CommonModal
        title="Edit Item"
        isModalOpen={isUpdateItemModalOpen}
        handleCancel={handleCancel}
        destroyOnClose
      >
        <ItemForm
          item={courseItem}
          handleCancel={handleCancel}
          refetchItems={refetchItems}
          activeCategory={activeCategory}
        />
      </CommonModal>
    </>
  )
}
