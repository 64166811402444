import React from "react";

export default function LogOutModal(props) {
  const { setLogoutModalVisible, handleMenuClick } = props;

  return (
    <>
      <div className="logout-modal-heading">
        Are you sure you want to Sign Out ?
      </div>
      <div className="card-footer">
        <div>
          <button
            className="order-reject-button"
            onClick={() => setLogoutModalVisible(false)}
          >
            <span>Cancel</span>
          </button>
        </div>
        <div>
          <button className="order-accept-button" onClick={handleMenuClick}>
            <span>Confirm</span>
          </button>
        </div>
      </div>
    </>
  );
}
