import { Badge } from "antd";
import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import CustomDatePicker from "../../../../components/CustomDatePicker/customDatePicker";
import SearchHeaderComponent from "../../../../components/HeaderComponents/search_header_component";
import StatusHeaderContainer from "../../../../components/HeaderComponents/status_header_component";
import {
  currentTabIdAtom,
  tabDataStatsSelector,
} from "../../../../recoils/dinein";
import { orderDetailsByIdAtom } from "../../../../recoils/orders";
import { searchAtom } from "../../../../recoils/helperAtoms";
import ClosedTabs from "./ClosedTabs";
import OpenTabs from "./OpenTabs";

export default function DineInDataTable(props) {
  const { columns, tableName, status } = props;
  const [currentStatus, setCurrentStatus] = useState(status[0]);
  const { pendingTabs, openTabs } = useRecoilValue(tabDataStatsSelector);
  const search = useRecoilValue(searchAtom);
  const setCurrentTab = useSetRecoilState(currentTabIdAtom);
  const setOrderDetailsById = useSetRecoilState(orderDetailsByIdAtom);
  const [filterDate, setFilterDate] = useState();

  // const dotVisible = (name) => {
  //   console.log(pendingTabs!=0);

  //   switch (name) {
  //     case "PENDING":
  //       return pendingTabs != 0;
  //     case "ACCEPTED":
  //       return openTabs != 0;
  //     default:
  //       return false;
  //   }
  // };

  return (
    <div>
      <div id="dataTable">
        <SearchHeaderComponent title={tableName} searchField={true} />
        <StatusHeaderContainer>
          <h3 className="ml-5">Status :</h3>
          {status.map((name, index) => {
            return (
              <button
                key={index}
                className={[
                  currentStatus === name
                    ? "ring-0 ring-offset-2 text-red-800 bg-red-200 ring-red-100"
                    : "",
                  "inline-flex ml-5 my-5 items-center border text-s font-medium rounded focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-white ",
                ]}
                onClick={() => {
                  setCurrentStatus(name);
                  setCurrentTab();
                  setOrderDetailsById();
                }}
              >
                <Badge
                  dot
                  count={
                    name === "PENDING TABS"
                      ? pendingTabs
                      : name === "OPEN TABS"
                      ? openTabs
                      : 0
                  }
                >
                  <span className="px-2 py-0">{name}</span>
                </Badge>
              </button>
            );
          })}
          {currentStatus === "CLOSED TABS" ? (
            <CustomDatePicker value={filterDate} setValue={setFilterDate} />
          ) : (
            <span />
          )}
        </StatusHeaderContainer>
      </div>
      {currentStatus === status[0] ? (
        <OpenTabs columns={columns} search={search} />
      ) : (
        <ClosedTabs
          search={search}
          filterDate={filterDate}
          currentStatus={currentStatus}
        />
      )}
    </div>
  );
}
