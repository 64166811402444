import { Col, Row } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useRecoilState, useSetRecoilState } from "recoil";
import { searchAtom } from "../../../recoils/helperAtoms";
import { takeAwayDataAtom } from "../../../recoils/takeaway";
import { useAuth } from "../../../services/auth";
import { getAllTakeAway } from "../../../services/takeaway";
import TakeAwayOrderDetails from "./TakeAwayOrderDetails";
import TakeAwayTable from "./TakeAwayTable";

export default function TakeAway() {
  const { getIdToken } = useAuth();
  const token = getIdToken();
  const [, setTakeAwayData] = useRecoilState(takeAwayDataAtom);
  const setSearch = useSetRecoilState(searchAtom);
  const { data: updatedTakeAwayData, isSuccess: fetchedAllTakeAway } = useQuery(
    "fetchAllTakeAwayData",
    () => getAllTakeAway(token)
  );

  React.useEffect(() => setSearch());

  React.useEffect(() => {
    if (fetchedAllTakeAway) {
      const sortedTakeAwayData = updatedTakeAwayData?.data?.data
        ?.slice()
        .reverse();
      setTakeAwayData(sortedTakeAwayData);
    }
  }, [updatedTakeAwayData, fetchedAllTakeAway, setTakeAwayData]);

  return (
    <div id="takeAway">
      <Row>
        <Col span={16}>
          <div>
            <TakeAwayTable />
          </div>
        </Col>
        <Col span={8}>
          <TakeAwayOrderDetails />
        </Col>
      </Row>
    </div>
  );
}
