import React from "react";
import { Row, Col } from "antd";
import BrandLogo from "../../assets/images/one-liner-logo.png";
import AppStoreLogo from "../../assets/images/app-store.png";
import PlayStoreLogo from "../../assets/images/play-store.png";
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";

export default function CommonFooter() {
  return (
    <div id='contact' className="lg:px-24 pt-12">
      <Logo />
      <Row justify="space-between">
        <Col xs={24} lg={12}>
          <Row
            className="text-sm font-medium py-5 links"
            style={{ color: "#212838" }}
          >
            <a className="mr-5" href="/about-us">
              About
            </a>
            <a className="mr-5" href="/FAQ">
              FAQ
            </a>
            <a className="mr-5" href="https://events.flunkey.app/" target={"_blank"}>
              What's Up Today
            </a>
            <a className="mr-5" href="/terms-and-service">
              Terms of Services
            </a>
            <a className="mr-5" href="/privacy-policy">
              Privacy Policy
            </a>
          </Row>
          <div
            style={{ color: "#3F4C6B" }}
            className="text-xs font-medium leading-5"
          >
            Flunkey is a highly customizable cloud platform that digitizes the
            F&B stores at zero cost
            <br /> and saves them money.
          </div>
          <div
            className="text-xs font-medium py-5"
            style={{ color: "#212838" }}
          >
            © 2022 Flunkey Inc.
          </div>
        </Col>
        <Col xs={24} lg={10}>
          <Row justify="space-between">
            <Col>
              <div
                className="text-sm font-medium py-5"
                style={{ color: "#212838" }}
              >
                Address
              </div>
              <div
                style={{ color: "#3F4C6B" }}
                className="text-xs font-medium leading-5"
              >
                F151 CHIN SWEE ROAD
                <br /> #07 - 12 MANHATTAN HOUSE
                <br /> SINGAPORE 169876
              </div>
            </Col>
            <Col>
              <div
                className="text-sm font-medium py-5"
                style={{ color: "#212838" }}
              >
                Contact
              </div>
              <div
                style={{ color: "#3F4C6B" }}
                className="text-xs font-medium leading-5"
              >
                contact@flunkey.app
                <br />
                +65 - 81834676
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
